import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import sortByDate from "./../../helpers/sortByDate";

import ListItem from "./ListItem";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

function List() {
  const [news, setNews] = useState();
  const [reload, setReload] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState();

  useEffect(() => {
    getNews()
  }, [reload])

  function handleReload() {
    setReload(prevCount => prevCount + 1);
  }

  async function getNews() {
    const q = query(collection(db, "news"));
    const querySnapshot = await getDocs(q);
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    });
    sortByDate(arr);
    setNews(arr);
  }
  return (
    <div className="row">
      {
        news &&
        news.map(news => 
          <ListItem
            key={news.id}
            news={news}
            setReload={setReload}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenUpdateModal={setOpenUpdateModal}
            setSelectedNews={setSelectedNews}
          />)
      }

      { 
        openDeleteModal &&
        <DeleteModal 
          selectedNews={selectedNews} 
          setOpenDeleteModal={setOpenDeleteModal}
          setReload={setReload}
          setSelectedNews={setSelectedNews}
        />
      }

      { 
        openUpdateModal &&
        <UpdateModal 
          selectedNews={selectedNews} 
          setOpenUpdateModal={setOpenUpdateModal}
          setReload={handleReload}
          setSelectedNews={setSelectedNews}
        />
      }
    </div>
  )
}

export default List;