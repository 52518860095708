import React from "react";
import { db } from "../../config/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { storage } from "../../config/firebase";
import { ref, deleteObject } from "firebase/storage";

function DeleteModal({setOpenDeleteModal, setReload, selectedSound}) {

  function handleClick(e) {
    if (e.target.classList.contains('backdrop') || e.target.classList.contains('cancel')) {
      setOpenDeleteModal(false);
    }
  }

  async function deleteSound() {
    const sound = doc(db, "sounds", selectedSound.id);
    await deleteDoc(sound);

    setOpenDeleteModal(false);
    setReload(prev => !prev);
  };

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="card delete-modal text-bg-light border-danger p-4">
        <h4 className="text-danger">Confirmer la suppression</h4>
        <p>Êtes-vous sûr·e de vouloir supprimer ce média ?</p>
        <div className="row">
          <div className="col">
            <button className="btn btn-sm btn-outline-secondary cancel" onClick={ handleClick }>
              Non, annuler
            </button>
          </div>
          <div className="col">
            <button className="btn btn-sm btn-outline-danger float-end" onClick={ deleteSound }>
              Oui, confirmer la suppression
            </button>
          </div>
        </div>
        
      </div>
    </div>
  )
};

export default DeleteModal;