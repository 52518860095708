import React from "react";

function About() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12 mb-5">
          <h6 className="mt-3 fw-bold text-decoration-underline">À Propos</h6>

          <p>L’école zéro est une association à but non lucratif, fondée en 2020, par des étudiants en architecture et de paysage, à la suite d’événements de contestations au sein de l’Ecole d’architecture Paris Malaquais.</p>
          <p>Partant du constat que les écoles d’architecture sont implantées en métropole, son objectif est d’explorer les milieux ruraux afin d’apprendre au contact de ceux qui vivent, habitent et travaillent le territoire.</p>
          <p>Elle expérimente une manière de faire école collectivement, en favorisant l'interaction des connaissances et savoir-faire liés aux domaines de l’art, l’architecture et l’aménagement du territoire, par l’expérience et la pratique, en valorisant d’abord ceux du territoire sur lequel elle intervient.</p>
          <p>Elle propose des espaces de rencontre, de partage, de recherche et d’expérimentation orientés autour de la notion de “faire”, ouvert à toutes et tous, issu.e.s ou non des milieux de la création et de la ville. Elle s’adresse et collabore avec une diversité d'acteurs publics et privés la plus large possible.</p>
          <p>Elle explore différents formats, de quelques jours à plusieurs semaines, en fonction des situations rencontrées. Elle propose chaque fois un format coopératif d'échange avec le territoire accueillant et avec les personnes donnant de leur temps. Elle n'a cependant pas vocation à faire du chantier participatif ou de la maîtrise d'œuvre.</p>
          <p>Dans une démarche de respect vis-à-vis du territoire sur lequel elle s’installe, mais aussi des participant.e.s, l'école zéro s’engage à proposer un cadre sain, bienveillant et respectueux du contexte.</p>

        </div>
        <div className="col-lg-6 col-md-12 mb-5">
          <h6 className="fw-bold text-decoration-underline">Qui sommes-nous&nbsp;?</h6>

          <p>L’association est aujourd’hui administrée par un bureau composé de :</p>
            <ul>
              <li>2 Co-présidentes : Caroline Lacroix et Hannah Höfte</li>
              <li>2 Trésorier.re.s : Kaan Pernek et Margaux Girerd</li>
              <li>5 membres actifs : Roxane Pelissier, France-Lan Le Vu, Elsa Valax, Clément Novaro, Jérôme Espitalier</li>
            </ul>
            <p>Le bureau est épaulé par les adhérent.e.s de l’association, qui peuvent être porteur.euses de projet, participer en soutien ponctuel ou régulier sur l’organisation de projets ou d’événements, selon leurs envies, les besoins et les projets tout au long de l’année.</p>

          <h6 className="mt-5 fw-bold text-decoration-underline">Ils et elles participent, ou ont participé à faire vivre l’association, merci à elles et eux&nbsp;:</h6>
            <p>Adèle, Alex, Alya, Amaury, Anaïs, Anaïs, Antoine, Benjamin, Benjamin, Bibiche, Blaise, Candice, Charles, Charly, Clément, Eléa, Elyssa, Estelle, Ewen, Franck, Jan, Jean-François, Louise, Lucille,  Marin, Marion, Maud, Nico, Nils, Pierre, Ségolène, Selin, Ulysse, Violette, …</p>

          <h6 className="mt-5"><a href="https://drive.google.com/file/d/1P5hgyUzvy2Z0DWLLqrnYjVu7ZwQSeE7r/view" target="_blank" rel="noreferrer" className="fw-bold">La “Charte collective” de nos actions&nbsp;↗</a></h6>
          <h6 className><a href="https://ecole-zero.assoconnect.com/collect/description/381198-m-adhesion-2024" target="_blank" rel="noreferrer" className="fw-bold">Adhérez à l’association pour soutenir son action et rejoindre ses actions&nbsp;↗</a></h6>
        </div>
      </div>
    </>
  )
}

export default About;