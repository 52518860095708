import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import sortByDate from "./../../helpers/sortByDate";

import ListItem from "./ListItem";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

function List() {
  const [publications, setPublications] = useState();
  const [reload, setReload] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState();

  useEffect(() => {
    getPublications()
  }, [reload])

  async function getPublications() {
    const q = query(collection(db, "publications"));
    const querySnapshot = await getDocs(q);
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    });
    sortByDate(arr);
    setPublications(arr);
  }
  return (
    <div className="row">
      {
        publications &&
        publications.map(publication =>
          <ListItem
            key={publication.id}
            publication={publication}
            setReload={setReload}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenUpdateModal={setOpenUpdateModal}
            setSelectedPublication={setSelectedPublication}
          />)
      }

      { 
        openDeleteModal &&
        <DeleteModal 
          selectedPublication={selectedPublication} 
          setOpenDeleteModal={setOpenDeleteModal} 
          setReload={setReload}
          setSelectedPublication={setSelectedPublication}
        />
      }

      { 
        openUpdateModal &&
        <UpdateModal 
          selectedPublication={selectedPublication} 
          setOpenUpdateModal={setOpenUpdateModal} 
          setReload={setReload}
          setSelectedPublication={setSelectedPublication}
        />
      }
    </div>
  )
}

export default List;