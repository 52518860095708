function sortByYear(array) {
  array.sort((a, b) => {
    let fa = a.year, fb = b.year;
    if (fa > fb) return -1;
    if (fa < fb) return 1;
    // If years are equal, sort by season
    let seasons = ['spring', 'summer', 'fall', 'winter'];
    let sa = seasons.indexOf(a.season), sb = seasons.indexOf(b.season);
    if (sa > sb) return -1;
    if (sa < sb) return 1;
    return 0;
  });
}

export default sortByYear;
