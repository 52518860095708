import React, { useState } from "react";
import { useAuth } from "./../../contexts/AuthContext";

function ListItem({ publication, setOpenDeleteModal, setSelectedPublication, setOpenUpdateModal }) {
  const { currentUser } = useAuth();
  const [localPublication, setLocalPublication] = useState(publication);

  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
    setSelectedPublication(localPublication);
  }

  function handleOpenUpdateModal() {
    setOpenUpdateModal(true);
    setSelectedPublication(localPublication);
  }
  
  return (
    <div className="row mb-4 ">
      <div className="col-lg-3 col-md-6 col-sm-12">
        <img src={ localPublication.imgUrl } className="img-fluid" alt="" />
        
      </div>
      <div className="col-lg-9 col-md-6 col-sm-12 d-flex flex-column justify-content-around">
        <div>
          <h6 className="fw-bold text-decoration-underline mt-3">{ localPublication.title }</h6>
          <p className="small">
            { localPublication.description }
          </p>
        </div>
        <p className="text-secondary small fst-italic">
          {
            `Publié le ${new Date(localPublication.date.seconds * 1000).toLocaleDateString('fr-FR')}`
          }
          {
            localPublication.editor &&
            <><br/>Avec <b>{localPublication.editor}</b></>
          }
          {
            localPublication.link &&
            <><br/><a href={localPublication.link} target="_blank" rel="noopener noreferrer">{localPublication.linkText}</a></>
          }
          
        </p>
        { 
          currentUser &&
          <div className="row">
            <div className="col">
              <button className="btn btn-sm btn-outline-primary me-2" onClick={ handleOpenUpdateModal }>Modifier</button>
              <button className="btn btn-sm btn-outline-danger" onClick={ handleOpenDeleteModal }>Supprimer</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ListItem;