import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./../../contexts/AuthContext";

import "./Navbar.css";
import logo from "./logoEcoleZero.svg";

function Navbar() {
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isViewportWide = window.innerWidth > 925;

  async function handleLogOut() {
    try {
      await logOut();
      navigate("/");
    } catch {
    }
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-lg-3">
          <Link to="/">
            <img src={ logo } className="logo" alt="Logo Ecole Zéro" />
          </Link>
        </div>
        <div className="col-lg-9 mt-md-4 mt-4">
          { 
            !currentUser &&
            <p className="text-secondary small fst-italic">
              École Zéro est une association à but non lucratif fondée en 2020 dont l'objectif est de réfléchir aux différentes manières de «&nbsp;faire école&nbsp;» collectivement, en mettant en relation l'aménagement du territoire avec différents champs disciplinaires et en proposant notamment d'explorer les territoires hors métropole. Elle est un espace de rencontre, de partage, de recherche et d'expérimentation à différentes échelles et sous des formes alternatives de création ouvert à tous·tes et s'adressant à une grande diversité d'acteur·ices public·ques et privé·es.
            </p>
          }
        </div>
      </div>
      {
        !currentUser &&
        <div className="row mt-3">
          <div className="col">
            <Link to="/" className={`nav-tab ${ location.pathname === "/" ? "active" : "" }`}>
              Actualités{ location.pathname === "/" ? " ↓" : "" }
            </Link>

            <span className="v-separator">|</span>

            <Link to="/workshops" className={`nav-tab ${ location.pathname.includes("/workshops") ? "active" : "" }`}>
              Ateliers{ location.pathname.includes("/workshops") ? " ↓" : "" }
            </Link>

            {
              isViewportWide ? <span className="v-separator">|</span> : <br/>
            }

            <Link to="/publications" className={`nav-tab ${ location.pathname.includes("/publications") ? "active" : "" }`}>
              Publications{ location.pathname.includes("/publications") ? " ↓" : "" }
            </Link>

            <span className="v-separator">|</span>

            <Link to="/sounds" className={`nav-tab ${ location.pathname.includes("/sounds") ? "active" : "" }`}>
              Médias{ location.pathname.includes("/sounds") ? " ↓" : "" }
            </Link>

            <span className="v-separator">|</span>

            <Link to="/about" className={`nav-tab ${ location.pathname.includes("/about") ? "active" : "" }`}>
              À&nbsp;propos{ location.pathname.includes("/about") ? " ↓" : "" }
            </Link>

            {/*

            <span className="v-separator">|</span>

            <Link to="/podcasts" className={`nav-tab ${ location.pathname.includes("/podcasts") ? "active" : "" }`}>
              Podcasts{ location.pathname.includes("/podcasts") ? " ↓" : "" }
            </Link>

            <span className="v-separator">|</span>

            <Link to="/directory" className={`nav-tab ${ location.pathname.includes("/directory") ? "active" : "" }`}>
              Annuaire{ location.pathname.includes("/directory") ? " ↓" : "" }
            </Link>

            <span className="v-separator">|</span>

            <Link to="/about" className={`nav-tab ${ location.pathname.includes("/about") ? "active" : "" }`}>
              À Propos{ location.pathname.includes("/about") ? " ↓" : "" }
            </Link> */}
          </div>
        </div>
      }

      {
        currentUser &&
        <div className="row mt-3">
          <div className="col">

          <span className="dropdown">
              <button className="dropdown-toggle nav-tab" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`${ location.pathname.includes("/news") ? "fw-bold" : "" }`}>
                  Actualités
                </span>
              </button>
              <ul className="dropdown-menu rounded-0 p-0">
                <li><Link to="/news/new" className="dropdown-item">Ajout d'une actualité</Link></li>
                <li><Link to="/news" className="dropdown-item">Gérer les actualités</Link></li>
              </ul>
            </span>

            <span className="v-separator">|</span>

            <span className="dropdown">
              <button className="dropdown-toggle nav-tab" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`${ location.pathname.includes("/workshops") ? "fw-bold" : "" }`}>
                  Ateliers
                </span>
              </button>
              <ul className="dropdown-menu rounded-0 p-0">
                <li><Link to="/workshops/new" className="dropdown-item">Ajout d'un atelier</Link></li>
                <li><Link to="/workshops" className="dropdown-item">Gérer les ateliers</Link></li>
              </ul>
            </span>

            {
              isViewportWide ? <span className="v-separator">|</span> : <br/>
            }

            <span className="dropdown">
              <button className="dropdown-toggle nav-tab" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`${ location.pathname.includes("/publications") ? "fw-bold" : "" }`}>
                  Publications
                </span>
              </button>
              <ul className="dropdown-menu rounded-0 p-0">
                <li><Link to="/publications/new" className="dropdown-item">Ajout d'une publication</Link></li>
                <li><Link to="/publications" className="dropdown-item">Gérer les publications</Link></li>
              </ul>
            </span>

            <span className="v-separator">|</span>

            <span className="dropdown">
              <button className="dropdown-toggle nav-tab" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`${ location.pathname.includes("/sounds") ? "fw-bold" : "" }`}>
                  Médias
                </span>
              </button>
              <ul className="dropdown-menu rounded-0 p-0">
                <li><Link to="/sounds/new" className="dropdown-item">Ajout d'un média</Link></li>
                <li><Link to="/sounds" className="dropdown-item">Gérer les médias</Link></li>
              </ul>
            </span>

            {
              isViewportWide ? <span className="v-separator">|</span> : <br/>
            }

            <Link to="/" onClick={ handleLogOut } className="nav-tab">
              Déconnexion
            </Link>
          </div>
        </div>
      }

      <div className="row mt-2 mb-3">
        <div className="col">
          <span className="h-separator"></span>
        </div>
      </div>
    </>
  )
  
}

export default Navbar;