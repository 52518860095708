import React from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";

function UpdateModal({selectedSound, setOpenUpdateModal, setReload, setSelectedSound}) {
  
  function handleClick(e) {
    if (e.target.classList.contains('backdrop') || e.target.classList.contains('cancel')) {
      setOpenUpdateModal(false);
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { 
      title: selectedSound.title, 
      description: selectedSound.description,
      date: new Date(selectedSound.date.seconds * 1000).toLocaleDateString('fr-FR'),
      link: selectedSound.link
     }
  });

  async function updateSound(data) {
    const eventDate = new Date(data.date.substr(6,4), (data.date.substr(3,2)-1), data.date.substr(0,2));
    await updateDoc(doc(db, "sounds", selectedSound.id), {
      title: data.title, 
      description: data.description,
      date: eventDate,
      link: data.link
    });
    setOpenUpdateModal(false);
    setSelectedSound();
    window.location.reload(false);
  }

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="card delete-modal text-bg-light border-dark p-4">
        <h4>Modifier le média</h4>
        <hr />
        <form>
          <label htmlFor="title" className="form-label">Titre</label>
          <input 
            type="text"
            id="title"
            className={ `form-control form-control-sm ${ errors.title && "is-invalid border-danger" }` }
            placeholder="Dupont"
            { ...register("title", { required: true }) }
          />
          { errors.title && <div className="form-text text-danger">Veuillez ajouter un titre</div> }


          <label htmlFor="description" className="form-label mt-3">Description</label>
          <textarea 
            type="text"
            id="description"
            className={ `form-control form-control-sm ${ errors.description && "is-invalid border-danger" }` }
            placeholder="Description"
            rows="6"
            maxLength="560"
            { ...register("description", { required: true, maxLength: 560 }) }
          />
          { errors.description && <div className="form-text text-danger">Veuillez ajouter une description</div> }

          <label htmlFor="date" className="form-label mt-3">Date</label>
          <input 
            type="text"
            id="date"
            className={ `form-control form-control-sm ${ errors.date && "is-invalid border-danger" }` }
            placeholder="JJ/MM/AAAA"
            { ...register("date", { required: true, maxLength: 10, pattern: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/ }) }
          />
          { errors.date && <div className="form-text text-danger">Veuillez ajouter une date au format JJ/MM/AAAA</div> }
          
          <label htmlFor="link" className="form-label mt-3">URL Soundcloud ou YouTube</label>
          <input 
            type="text"
            id="link"
            className={ `form-control form-control-sm ${ errors.link && "is-invalid border-danger" }` }
            placeholder="URL"
            { ...register("link", { required: true, pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }) }
          />
          { errors.link && <div className="form-text text-danger">Veuillez ajouter une URL</div> }

          <button className="btn btn-primary w-100 mt-4 mb-2" onClick={ handleSubmit(updateSound) } type="submit">Modifier le média</button>
        </form>
      </div>
    </div>
  )
};

export default UpdateModal;