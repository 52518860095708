function yearsSelector() {
  const currentYear = new Date().getFullYear();
  const startYear = 2019;
  const years = [];
  for (let i = startYear; i <= currentYear + 1; i++) {
    years.push(i);
  }
  return years;
}

export default yearsSelector;