import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";
import yearsSelector from "../../helpers/yearsSelector";

function UpdateModal({selectedWorkshop, setOpenUpdateModal, setReload, setSelectedWorkshop}) {
  function handleClick(e) {
    if (e.target.classList.contains('backdrop') || e.target.classList.contains('cancel')) {
      setOpenUpdateModal(false);
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { 
      title: selectedWorkshop.title, 
      season: selectedWorkshop.season,
      year: selectedWorkshop.year
     }
  });

  async function updateWorkshop(data) {
    await updateDoc(doc(db, "workshops", selectedWorkshop.id), {
      title: data.title, 
      season: data.season,
      year: data.year
    });
    setOpenUpdateModal(false);
    setSelectedWorkshop();
    window.location.reload(false);
  }

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="card delete-modal text-bg-light border-dark p-4">
        <h4>Modifier l'atelier</h4>
        <hr />
        <form>
          <label htmlFor="title" className="form-label">Titre</label>
          <input 
            type="text"
            id="title"
            className={ `form-control form-control-sm ${ errors.title ? "is-invalid border-danger" : "border-dark" }` }
            placeholder="Dupont"
            { ...register("title", { required: true }) }
          />
          { errors.title && <div className="form-text text-danger">Veuillez ajouter un titre</div> }
          
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <label htmlFor="season" className="form-label mt-4">Saison</label>
              <select 
                {...register("season", { required: true })}
                className={`form-control form-control-sm ${ errors.season ? "is-invalid border-danger" : "border-dark" }`}
              >
                <option value="">Saison</option>
                <option value="summer">Été</option>
                <option value="fall">Automne</option>
                <option value="winter">Hiver</option>
                <option value="spring">Printemps</option>
              </select>
              { errors.season && <div className="form-text text-danger">Veuillez choisir la saison à laquelle l'atelier a eu lieu</div> }
            </div>
            <div className="col-lg-6 col-md-12">
              <label htmlFor="year" className="form-label mt-4">Année</label>
              <select 
                {...register("year", { required: true })}
                className={`form-control form-control-sm ${ errors.year ? "is-invalid border-danger" : "border-dark" }`}
              >
                <option value="">Année</option>
                { 
                  yearsSelector().map(year => (
                    <option key={ year } value={ year }>{ year }</option>
                  ))
                }
              </select>
              { errors.year && <div className="form-text text-danger">Veuillez choisir l'année à laquelle l'atelier a eu lieu</div> }
            </div>
          </div>

          <button className="btn btn-primary w-100 mt-4 mb-2" onClick={ handleSubmit(updateWorkshop) } type="submit">Modifier l'atelier</button>
        </form>
      </div>
    </div>
  )
};

export default UpdateModal;