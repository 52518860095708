import React, { useState } from "react";
import { useAuth } from "./../../contexts/AuthContext";
import ReactPlayer from 'react-player'

function ListItem({ sound, setOpenDeleteModal, setSelectedSound, setOpenUpdateModal }) {
  const { currentUser } = useAuth();
  const [localSound, setLocalSound] = useState(sound);

  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
    setSelectedSound(localSound);
  }
  
  function handleOpenUpdateModal() {
    setOpenUpdateModal(true);
    setSelectedSound(localSound);
  }

  return (
    <div className="row mb-4 ">
      <div className="col-lg-3 col-md-6 col-sm-12">
        <ReactPlayer url={localSound.link} width="100%" height="100%" />
      </div>
      <div className="col-lg-9 col-md-6 col-sm-12 d-flex flex-column justify-content-around">
        <div>
          <h6 className="fw-bold text-decoration-underline mt-3">{ localSound.title }</h6>
          <p className="small">
            { localSound.description }
          </p>
        </div>
        <p className="text-secondary small fst-italic">
          {
            `Publié le ${new Date(localSound.date.seconds * 1000).toLocaleDateString('fr-FR')}`
          }
        </p>
        { 
          currentUser &&
          <div className="row">
            <div className="col">
              <button className="btn btn-sm btn-outline-primary me-2" onClick={ handleOpenUpdateModal }>Modifier</button>
              <button className="btn btn-sm btn-outline-danger" onClick={ handleOpenDeleteModal }>Supprimer</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ListItem;