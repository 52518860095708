import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { v4 } from "uuid";

import Alert from "./../Alert/Alert";

function Create() {
  const { register, watch, handleSubmit, reset, unregister, formState: { errors } } = useForm();
  const watchLinkCheck = watch("linkCheck");
  const watchTextLength = watch("description");
  const [countText, setCountText] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (watchLinkCheck) {
      register("link");
      register("linkText");
    } else {
      unregister("link");
      unregister("linkText");
    }
  }, [register, unregister, watchLinkCheck]);

  useEffect(() => {
    setCountText(watchTextLength ? watchTextLength.length : 0);
  }, [watchTextLength]);
  
  async function onSubmit(data) {
    const soundUid = v4();
    const createdAt = new Date().toISOString();
    const publishDate = new Date(data.date.substr(6,4), (data.date.substr(3,2)-1), data.date.substr(0,2));
    
    const soundData = {
      id: soundUid,
      title: data.title,
      description: data.description,
      createdAt: createdAt,
      date: publishDate,
      link: data.link
    };
  
    await setDoc(doc(db, "sounds", soundUid), soundData);
    setSuccess(true);
    reset();
  }

  return (
    <div className="row">
      <div className="col">
        <form>
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12">
              <label htmlFor="title" className="form-label">Titre du média</label>
              <input 
                type="text"
                id="title"
                className={ `form-control form-control-sm ${ errors.title ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Radio Zéro"
                { ...register("title", { required: true }) }
              />
              { errors.title && <div className="form-text text-danger">Un titre est requis</div> }
             
              <label htmlFor="description" className="form-label mt-4">Description <span className="text-muted">(560 caractères max)</span></label>
              <textarea 
                type="text"
                id="description"
                rows="4"
                className={ `form-control form-control-sm ${ errors.description ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi finibus nisl vel mauris venenatis, vel imperdiet dolor suscipit. Donec mollis urna id velit tincidunt tristique. Duis id purus mi. Nullam non felis ut est placerat sollicitudin a eget urna. Integer bibendum laoreet nisl, a bibendum ipsum pulvinar ut. Donec scelerisque hendrerit ante et accumsan. Nullam malesuada bibendum magna eu lobortis. Praesent efficitur, metus id aliquam placerat, mauris ligula elementum est, a dictum metus sapien quis ex. Curabitur volutpat urna elit, in finibus nullam."
                maxLength="560"
                { ...register("description", { required: true, maxLength: 560 }) }
              />
              
              <div className="row">
                <div className="col-6">
                  { errors.description && <div className="form-text text-danger">Une description de la publication est requise</div> }
                </div>
                <div className="col-6">
                  <p className="text-end mb-0 form-text">{ countText }/560</p> 
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label htmlFor="date" className="form-label mt-0">Date de publication</label>
                  <input 
                    type="text"
                    id="date"
                    className={ `form-control form-control-sm ${ errors.date ? "is-invalid border-danger" : "border-dark" }` }
                    placeholder="JJ/MM/AAAA"
                    maxLength="10"
                    { ...register("date", { required: true, maxLength: 10, pattern: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/ }) }
                  />
                  { errors.date && <div className="form-text text-danger">Vous devez ajouter une date, ou le format de la date est incorrect</div> }
                </div>
                <div className="col-6">
                  <label htmlFor="link" className="form-label mt-0">URL Soundcloud ou YouTube</label>
                  <input 
                    type="text"
                    id="link"
                    className={ `form-control form-control-sm ${ errors.link ? "is-invalid border-danger" : "border-dark" }` }
                    placeholder="https://soundcloud.com/etc"
                    { ...register("link", { required: true, pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }) }
                  />
                  { errors.link && <div className="form-text text-danger">Veuillez entrer un lien Soundcloud ou YouTube, ou le format du lien semble incorrect</div> }
                </div>
              </div>

              <button className={ `btn mt-4 mb-3 btn-primary w-100` } onClick={ handleSubmit(onSubmit) } type="submit">Ajouter le média</button>
            </div>
          </div>
        </form>

        {
          success && 
          <Alert message="Podcast ajouté avec succès." setSuccess={ setSuccess } />
        }

      </div>
    </div>
  )
}

export default Create;