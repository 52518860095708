import React, { useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import sortByDate from "./../../helpers/sortByDate";

import ListItem from "./ListItem";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

function List() {
  const [sounds, setSounds] = useState();
  const [reload, setReload] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedSound, setSelectedSound] = useState();

  useEffect(() => {
    getSounds()
  }, [reload])

  async function getSounds() {
    const q = query(collection(db, "sounds"));
    const querySnapshot = await getDocs(q);
    const arr = []
    querySnapshot.forEach((doc) => {
      arr.push(doc.data())
    });
    sortByDate(arr);
    setSounds(arr);
  }
  return (
    <div className="row">
      {
        sounds &&
        sounds.map(sound => 
          <ListItem
            key={sound.id}
            sound={sound}
            setReload={setReload}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenUpdateModal={setOpenUpdateModal}
            setSelectedSound={setSelectedSound}
          />)
      }

      { 
        openDeleteModal &&
        <DeleteModal 
          selectedSound={selectedSound} 
          setOpenDeleteModal={setOpenDeleteModal} 
          setReload={setReload}
          setSelectedSound={setSelectedSound}
        />
      }

{ 
        openUpdateModal &&
        <UpdateModal 
          selectedSound={selectedSound} 
          setOpenUpdateModal={setOpenUpdateModal} 
          setReload={setReload}
          setSelectedSound={setSelectedSound}
        />
      }
    </div>
  )
}

export default List;