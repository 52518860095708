import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";

function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { logIn, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, []);

  async function submitLogIn(data) {
    try {
      setError("");
      setLoading(true);
      await logIn(data.email, data.password);
      navigate("/");
    } catch (err) {
      setError("Échec de la connexion");
    }
    setLoading(false);
  }

  return (
    <div className="row">
      <div className="col-lg-4 col-md-12">
        <h6 className="mt-3 fw-bold text-decoration-underline">Administration</h6>

        { error && <div className="alert alert-danger text-danger bg-dark border-danger mt-1">💥 { error }</div> }

        <form className="mt-3">
          <input 
            type="email"
            id="email"
            className={ `form-control border-dark ${ errors.email && "is-invalid border-danger" }` }
            placeholder="Email"
            { ...register("email", { required: true, pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i }) }
          />
          { errors.email && <div className="form-text text-danger">Enter email address</div> }
          <input 
            type="password"
            id="password"
            className={ `form-control border-dark mt-2 ${ errors.password && "is-invalid border-danger" }` }
            placeholder="······"
            { ...register("password", { required: true, minLength: 6 }) }
          />
          { errors.password && <div className="form-text text-danger">Password must be at least 6 charaecters long</div> }

          <button className={ `btn mt-3 mb-5 btn-dark btn-sm` } onClick={ handleSubmit(submitLogIn) } disabled={ loading } type="submit">Connexion</button>
        </form>

      </div>
    </div>
  )
}

export default Login;