import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import RgbQuant from "rgbquant";

import Alert from "./../Alert/Alert";

function Create() {
  const { register, watch, handleSubmit, reset, unregister, formState: { errors } } = useForm();
  const watchLinkCheck = watch("linkCheck");
  const watchTextLength = watch("description");
  const [countText, setCountText] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (watchLinkCheck) {
      register("link");
      register("linkText");
    } else {
      unregister("link");
      unregister("linkText");
    }
  }, [register, unregister, watchLinkCheck]);

  useEffect(() => {
    setCountText(watchTextLength ? watchTextLength.length : 0);
  }, [watchTextLength]);

  async function onSubmit(data) {
    const publicationUid = v4();
    const createdAt = new Date().toISOString();
    const publishDate = new Date(data.date.substr(6,4), data.date.substr(3,2), data.date.substr(0,2));
    const image = data.file[0];
    const storageRef = ref(storage, `gs://ecole-zero.appspot.com/publicationsImages/${publicationUid}`);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = URL.createObjectURL(image);
  
    await new Promise((resolve) => {
      img.onload = () => {
        const aspectRatio = 500 / 333;
        let sourceWidth = img.width;
        let sourceHeight = img.height;
        const sourceAspectRatio = sourceWidth / sourceHeight;
        let destX = 0;
        let destY = 0;
        let destWidth = sourceWidth;
        let destHeight = sourceHeight;
  
        if (sourceAspectRatio > aspectRatio) {
          destWidth = sourceHeight * aspectRatio;
          destX = (sourceWidth - destWidth) / 2;
        } else if (sourceAspectRatio < aspectRatio) {
          destHeight = sourceWidth / aspectRatio;
          destY = (sourceHeight - destHeight) / 2;
        }
  
        canvas.width = 500;
        canvas.height = 333;
        ctx.drawImage(img, destX, destY, destWidth, destHeight, 0, 0, canvas.width, canvas.height);
        resolve();
      };
    });
  
    const opts = {
      colors: 8,
      minHueCols: 0,
      dithKern: "FloydSteinberg",
      dithSerp: false,
    };
    const quant = new RgbQuant(opts);
    quant.sample(canvas);
    const palette = quant.palette();
    const dithered = quant.reduce(canvas);
    const ditheredImage = new ImageData(
      new Uint8ClampedArray(dithered.buffer),
      canvas.width,
      canvas.height
    );
    ctx.putImageData(ditheredImage, 0, 0);
  
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, image.type);
    });
  
    const uploadTask = uploadBytesResumable(storageRef, blob);
  
    uploadTask.on('state_changed',
      () => {
        // snapshot state change
      },
      (error) => {
        // console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            createPublication(publicationUid, data, createdAt, publishDate, downloadURL);
            reset();
          });
      });
  }
  
  async function createPublication(publicationUid, data, createdAt, publishDate, downloadUrl) {
    const publicationData = {
      id: publicationUid,
      title: data.title,
      description: data.description,
      createdAt: createdAt,
      imgUrl: downloadUrl,
      date: publishDate,
      ...(data.editor && {editor: data.editor}),
      ...(data.link && {link: data.link}),
      ...(data.linkText && {linkText: data.linkText}),
    };
  
    await setDoc(doc(db, "publications", publicationUid), publicationData);
    setSuccess(true);
    reset();
  }

  return (
    <div className="row">
      <div className="col">
        <form>
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12">
              <label htmlFor="title" className="form-label">Titre de la publication</label>
              <input 
                type="text"
                id="title"
                className={ `form-control form-control-sm ${ errors.title ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="École Zéro, itinéraire d'une école en construction"
                { ...register("title", { required: true }) }
              />
              { errors.title && <div className="form-text text-danger">Un titre est requis</div> }
             
              <label className="form-label mt-4">Illustration <span className="text-muted">(L'image sera recadrée au format paysage)</span></label>
              <input 
                type="file"
                { ...register("file", { required: true }) }
                className={`form-control form-control-sm ${ errors.file ? "is-invalid border-danger" : "border-dark" }`}
              />
              { errors.file && <div className="form-text text-danger">Veuillez choisir un fichier</div> }

              <label htmlFor="description" className="form-label mt-4">Description <span className="text-muted">(560 caractères max)</span></label>
              <textarea 
                type="text"
                id="description"
                rows="4"
                className={ `form-control form-control-sm ${ errors.description ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi finibus nisl vel mauris venenatis, vel imperdiet dolor suscipit. Donec mollis urna id velit tincidunt tristique. Duis id purus mi. Nullam non felis ut est placerat sollicitudin a eget urna. Integer bibendum laoreet nisl, a bibendum ipsum pulvinar ut. Donec scelerisque hendrerit ante et accumsan. Nullam malesuada bibendum magna eu lobortis. Praesent efficitur, metus id aliquam placerat, mauris ligula elementum est, a dictum metus sapien quis ex. Curabitur volutpat urna elit, in finibus nullam."
                maxLength="560"
                { ...register("description", { required: true, maxLength: 560 }) }
              />
              
              <div className="row">
                <div className="col-6">
                  { errors.description && <div className="form-text text-danger">Une description de la publication est requise</div> }
                </div>
                <div className="col-6">
                  <p className="text-end mb-0 form-text">{ countText }/560</p> 
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label htmlFor="date" className="form-label mt-0">Date de publication</label>
                  <input 
                    type="text"
                    id="date"
                    className={ `form-control form-control-sm ${ errors.date ? "is-invalid border-danger" : "border-dark" }` }
                    placeholder="JJ/MM/AAAA"
                    maxLength="10"
                    { ...register("date", { required: true, maxLength: 10, pattern: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/ }) }
                  />
                  { errors.date && <div className="form-text text-danger">Vous devez ajouter une date, ou le format de la date est incorrect</div> }
                </div>
                <div className="col-6">
                  <label htmlFor="editor" className="form-label mt-0">Éditeur</label>
                  <input 
                    type="text"
                    id="editor"
                    className={ `form-control form-control-sm ${ errors.editor ? "is-invalid border-danger" : "border-dark" }` }
                    placeholder="Éditions Burn~Août"
                    { ...register("editor", { maxLength: 200 }) }
                  />
                  { errors.editor && <div className="form-text text-danger">Le nom de l'éditeur est trop long</div> }
                </div>
              </div>
              
              <div className="form-check mt-4">
                <input
                  type="checkbox"
                  id="linkCheck"
                  className={ `form-check-input ${ errors.linkCheck ? "is-invalid border-danger" : "border-dark" }` }
                  {...register("linkCheck")}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Je souhaite ajouter un lien
                </label>
              </div>

              { watchLinkCheck &&
                <>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="link" className="form-label mt-2">URL du lien</label>
                    <input 
                      type="text"
                      id="link"
                      className={ `form-control form-control-sm ${ errors.link ? "is-invalid border-danger" : "border-dark" }` }
                      placeholder="http://www.domain.com"
                      { ...register("link", { pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }) }
                    />
                    { errors.link && <div className="form-text text-danger">Le format du lien semble incorrect</div> }
                  </div>
                  <div className="col-6">
                    <label htmlFor="linkText" className="form-label mt-2">Texte du lien</label>
                    <input 
                      type="text"
                      id="linkText"
                      className={ `form-control form-control-sm ${ errors.linkText ? "is-invalid border-danger" : "border-dark" }` }
                      placeholder="Inscription, plus d'infos, etc"
                      { ...register("linkText", { required: true }) }
                    />
                    { errors.linkText && <div className="form-text text-danger">Vous devez ajouter un texte de lien</div> }
                  </div>
                </div>
                  
                </>
              }

              <button className={ `btn mt-5 mb-3 btn-primary w-100` } onClick={ handleSubmit(onSubmit) } type="submit">Ajouter la publication</button>
            </div>
          </div>
        </form>

        {
          success && 
          <Alert message="Publication ajoutée avec succès." setSuccess={ setSuccess } />
        }

      </div>
    </div>
  )
}

export default Create;