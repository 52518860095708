import React, { useState } from "react";
import { useAuth } from "./../../contexts/AuthContext";

function ListItem({ news, setOpenDeleteModal, setOpenUpdateModal, setSelectedNews }) {
  const { currentUser } = useAuth();
  const [localNews, setLocalNews] = useState(news);

  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
    setSelectedNews(localNews);
  }

  function handleOpenUpdateModal() {
    setOpenUpdateModal(true);
    setSelectedNews(localNews);
  }
  
  return (
    <div className="row mb-4 ">
      <div className="col-lg-3 col-md-6 col-sm-12">
        <img src={ localNews.imgUrl } className="img-fluid" alt="" />
        
      </div>
      <div className="col-lg-9 col-md-6 col-sm-12 d-flex flex-column justify-content-around">
        <div>
          <h6 className="fw-bold text-decoration-underline mt-3">{ localNews.title }</h6>
          <p className="small">
            { localNews.description }
          </p>
        </div>
        <p className="text-secondary small fst-italic">
          {
            `Le ${new Date(localNews.date.seconds * 1000).toLocaleDateString('fr-FR')}`
          }
          {
            localNews.location &&
            <><br/>{localNews.location}</>
          }
          {
            localNews.link &&
            <><br/><a href={localNews.link} target="_blank" rel="noopener noreferrer">{localNews.linkText}</a></>
          }
          
        </p>
        { 
          currentUser &&
          <div className="row">
            <div className="col">
              <button className="btn btn-sm btn-outline-primary me-2" onClick={ handleOpenUpdateModal }>Modifier</button>
              <button className="btn btn-sm btn-outline-danger" onClick={ handleOpenDeleteModal }>Supprimer</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ListItem;