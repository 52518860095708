import React from "react";
import { useAuth } from "./../../contexts/AuthContext";

import "./Footer.css";

function Footer() {
  const { currentUser } = useAuth();

  return (
    <>
      <div className="row">
        <div className="col">
          <span className="h-separator-footer"></span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-12">
          {/* { 
            !currentUser &&
            <p className="text-secondary small fst-italic">
              École Zéro est une association à but non lucratif fondée en 2020 dont l'objectif est de réfléchir aux différentes manières de «&nbsp;faire école&nbsp;» collectivement, en mettant en relation l'aménagement du territoire avec différents champs disciplinaires et en proposant notamment d'explorer les territoires hors métropole. Elle est un espace de rencontre, de partage, de recherche et d'expérimentation à différentes échelles et sous des formes alternatives de création ouvert à tous·tes et s'adressant à une grande diversité d'acteur·ices public·ques et privé·es.
            </p>
          } */}
          
        </div>
        <div className="col-lg-6 col-md-12 small text-end">
            {
              currentUser &&
              <p>
                Une question sur le site ? <a href="mailto:antoine@mlav.land">antoine@mlav.land ↗</a>
              </p>
            }
            {
              !currentUser &&
              <p>
                Écrivez-nous à <u>bonjour@ecolezero.com</u><br/>
                <a href="https://www.instagram.com/ecole_zero/">Suivez nous sur Instagram ↗</a><br/>
                <a href="https://airtable.com/shrvwZ8V8oLMqa05J">S'abonner à la newsletter ↗</a>
              </p>
            }
        </div>
      </div>
    </>
  )
  
}

export default Footer;