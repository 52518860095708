import React, { useState } from "react";
import { useAuth } from "./../../contexts/AuthContext";
import seasonFormatter from "./../../helpers/seasonFormatter";

function ListItem({workshop, setOpenDeleteModal, setOpenUpdateModal, setSelectedWorkshop}) {
  const {currentUser} = useAuth();
  const [localWorkshop, setLocalWorkshop] = useState(workshop);

  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
    setSelectedWorkshop(localWorkshop);
  }

  function handleOpenUpdateModal() {
    setOpenUpdateModal(true);
    setSelectedWorkshop(localWorkshop);
  }

  return (
    <div className="col-lg-3 col-md-6 col-12 mb-2 d-flex justify-content-between flex-column">
      <div className="">
        <img src={localWorkshop.imgUrl} className="img-fluid" alt="" />
        
        <h6 className="mt-3 fw-bold text-decoration-underline">{localWorkshop.title}</h6>

        <p className="mt-2 text-secondary small fst-italic">
          {seasonFormatter(localWorkshop.season)}
          {localWorkshop.year}
        </p>
      </div>

      { 
        currentUser &&
        <div className="row mb-3">
          <div className="col">
            <button className="btn btn-sm btn-outline-primary me-2" onClick={handleOpenUpdateModal}>Modifier</button>
            <button className="btn btn-sm btn-outline-danger" onClick={handleOpenDeleteModal}>Supprimer</button>
          </div>
        </div>
      }
    </div>
  );
}

export default ListItem;