function seasonFormatter(season) {
  switch (season) {
    case "summer":
      return "Session d'été ";
    case "fall":
      return "Session d'automne ";
    case "winter":
      return "Session d'hiver ";
    case "spring":
      return "Session de printemps ";
    default:
      break;
  }
}

export default seasonFormatter;