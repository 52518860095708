import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";

import './App.css';

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Error from "./components/Error/Error";

import Login from "./components/Auth/Login";
import ListNews from "./components/News/List";
import CreateNews from "./components/News/Create";
import ListWorkshops from "./components/Workshops/List";
import CreateWorkshop from "./components/Workshops/Create";
import ListPublications from "./components/Publications/List";
import CreatePublication from "./components/Publications/Create";
import ListSounds from "./components/Sounds/List";
import CreateSound from "./components/Sounds/Create";
import About from "./components/About/About";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="container-fluid p-5">

          <Navbar />

          <Routes>
            <Route exact path="/" element={ <ListNews /> } />
            <Route exact path="/admin" element={ <Login /> } />
            <Route exact path="/news" element={ <ListNews /> } />
            <Route exact path="/news/new" element={ <PrivateRoute><CreateNews /></PrivateRoute> } />
            <Route exact path="/workshops" element={ <ListWorkshops /> } />
            <Route exact path="/workshops/new" element={ <PrivateRoute><CreateWorkshop /></PrivateRoute> } />
            <Route exact path="/publications" element={ <ListPublications /> } />
            <Route exact path="/publications/new" element={ <PrivateRoute><CreatePublication /></PrivateRoute> } />
            <Route exact path="/sounds" element={ <ListSounds /> } />
            <Route exact path="/sounds/new" element={ <PrivateRoute><CreateSound /></PrivateRoute> } />
            <Route exact path="/about" element={ <About /> } />
            <Route path="*" element={ <Error /> } />
          </Routes>

          <Footer />

        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
