import React from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";

function UpdateModal({selectedNews, setOpenUpdateModal, setReload, setSelectedNews}) {
  
  function handleClick(e) {
    if (e.target.classList.contains('backdrop') || e.target.classList.contains('cancel')) {
      setOpenUpdateModal(false);
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { 
      title: selectedNews.title, 
      description: selectedNews.description,
      date: new Date(selectedNews.date.seconds * 1000).toLocaleDateString('fr-FR'),
      ...(selectedNews.location && {location: selectedNews.location}),
      ...(selectedNews.link && {link: selectedNews.link}),
      ...(selectedNews.linkText && {linkText: selectedNews.linkText}),
     }
  });

  async function updateNews(data) {
    const eventDate = new Date(data.date.substr(6,4), (data.date.substr(3,2)-1), data.date.substr(0,2));
    await updateDoc(doc(db, "news", selectedNews.id), {
      title: data.title, 
      description: data.description,
      date: eventDate,
      ...(data.location && {location: data.location}),
      ...(data.link && {link: data.link}),
      ...(data.linkText && {linkText: data.linkText}),
    });
    setOpenUpdateModal(false);
    setSelectedNews();
    window.location.reload(false);
  }

  return (
    <div className="backdrop" onClick={ handleClick }>
      <div className="card delete-modal text-bg-light border-dark p-4">
        <h4>Modifier l'actualité</h4>
        <hr />
        <form>
          <label htmlFor="title" className="form-label">Titre</label>
          <input 
            type="text"
            id="title"
            className={ `form-control form-control-sm ${ errors.title ? "is-invalid border-danger" : "border-dark" }` }
            placeholder="Dupont"
            { ...register("title", { required: true }) }
          />
          { errors.title && <div className="form-text text-danger">Veuillez ajouter un titre</div> }


          <label htmlFor="description" className="form-label mt-3">Description</label>
          <textarea 
            type="text"
            id="description"
            className={ `form-control form-control-sm ${ errors.description ? "is-invalid border-danger" : "border-dark" }` }
            placeholder="Description"
            rows="6"
            maxLength="560"
            { ...register("description", { required: true, maxLength: 560 }) }
          />
          { errors.description && <div className="form-text text-danger">Veuillez ajouter une description</div> }

          <label htmlFor="date" className="form-label mt-3">Date</label>
          <input 
            type="text"
            id="date"
            className={ `form-control form-control-sm ${ errors.date ? "is-invalid border-danger" : "border-dark" }` }
            placeholder="JJ/MM/AAAA"
            { ...register("date", { required: true, maxLength: 10, pattern: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/ }) }
          />
          { errors.date && <div className="form-text text-danger">Veuillez ajouter une date au format JJ/MM/AAAA</div> }
          
          {
            selectedNews.location &&
            <>
              <label htmlFor="location" className="form-label mt-3">Lieu de l'évènement</label>
              <input 
                type="text"
                id="location"
                className={ `form-control form-control-sm ${ errors.location ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Lieu"
                { ...register("location", { required: true }) }
              />
              { errors.location && <div className="form-text text-danger">Veuillez ajouter une indication de lieu</div> }
            </>
          }

          {
            selectedNews.link &&
            <div className="row">
              <div className="col-6">
                <label htmlFor="link" className="form-label mt-3">URL du lien</label>
                <input 
                  type="text"
                  id="link"
                  className={ `form-control form-control-sm ${ errors.link ? "is-invalid border-danger" : "border-dark" }` }
                  placeholder="URL"
                  { ...register("link", { required: true, pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }) }
                />
                { errors.link && <div className="form-text text-danger">Veuillez ajouter une URL</div> }
              </div>
              <div className="col-6">
                <label htmlFor="linkText" className="form-label mt-3">Texte du lien</label>
                <input 
                  type="text"
                  id="linkText"
                  className={ `form-control form-control-sm ${ errors.linkText ? "is-invalid border-danger" : "border-dark" }` }
                  placeholder="URL"
                  { ...register("linkText", { required: true }) }
                />
                { errors.linkText && <div className="form-text text-danger">Veuillez ajouter un texte de lien</div> }
              </div>
            </div>
          }

          <button className="btn btn-primary w-100 mt-4 mb-2" onClick={ handleSubmit(updateNews) } type="submit">Modifier l'actualité</button>
        </form>
      </div>
    </div>
  )
};

export default UpdateModal;