import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-12 mb-5">
          <h6 className="mt-3 fw-bold text-decoration-underline">Erreur</h6>

          <p>La page demandée n'est pas disponible.</p>

          <Link to="/">Retour à l'accueil</Link>
        </div>
      </div>
    </>
  )
}

export default Error;