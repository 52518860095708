import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import RgbQuant from "rgbquant";
import yearsSelector from "../../helpers/yearsSelector";

import Alert from "./../Alert/Alert";

function Create() {
  const { register, watch, handleSubmit, reset, formState: { errors } } = useForm();
  const watchTextLength = watch("description");
  const [countText, setCountText] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setCountText(watchTextLength ? watchTextLength.length : 0);
  }, [watchTextLength]);

  async function onSubmit(data) {
    const workshopUid = v4();
    const createdAt = new Date().toISOString();
    const image = data.file[0];
    const storageRef = ref(storage, `gs://ecole-zero.appspot.com/workshopImages/${workshopUid}`);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = URL.createObjectURL(image);
  
    await new Promise((resolve) => {
      img.onload = () => {
        const aspectRatio = 500 / 333;
        let sourceWidth = img.width;
        let sourceHeight = img.height;
        const sourceAspectRatio = sourceWidth / sourceHeight;
        let destX = 0;
        let destY = 0;
        let destWidth = sourceWidth;
        let destHeight = sourceHeight;
  
        if (sourceAspectRatio > aspectRatio) {
          destWidth = sourceHeight * aspectRatio;
          destX = (sourceWidth - destWidth) / 2;
        } else if (sourceAspectRatio < aspectRatio) {
          destHeight = sourceWidth / aspectRatio;
          destY = (sourceHeight - destHeight) / 2;
        }
  
        canvas.width = 500;
        canvas.height = 333;
        ctx.drawImage(img, destX, destY, destWidth, destHeight, 0, 0, canvas.width, canvas.height);
        resolve();
      };
    });
  
    const opts = {
      colors: 8,
      minHueCols: 0,
      dithKern: "FloydSteinberg",
      dithSerp: false,
    };
    const quant = new RgbQuant(opts);
    quant.sample(canvas);
    const palette = quant.palette();
    const dithered = quant.reduce(canvas);
    const ditheredImage = new ImageData(
      new Uint8ClampedArray(dithered.buffer),
      canvas.width,
      canvas.height
    );
    ctx.putImageData(ditheredImage, 0, 0);
  
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, image.type);
    });
  
    const uploadTask = uploadBytesResumable(storageRef, blob);
  
    uploadTask.on('state_changed',
      () => {
        // snapshot state change
      },
      (error) => {
        // console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            createWorkshop(workshopUid, data.title, data.season, data.year, data.description, createdAt, downloadURL);
            reset();
          });
      });
  }
  
  async function createWorkshop(workshopUid, title, season, year, description, createdAt, downloadUrl) {
    const workshopData = {
      id: workshopUid,
      title: title,
      season: season,
      year: year,
      description: "",
      createdAt: createdAt,
      imgUrl: downloadUrl,
    };
  
    await setDoc(doc(db, "workshops", workshopUid), workshopData);
    setSuccess(true);
    reset();
  }

  return (
    <div className="row">
      <div className="col">
        <form>
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12">
              <label htmlFor="title" className="form-label">Titre</label>
              <input 
                type="text"
                id="title"
                className={ `form-control form-control-sm ${ errors.title ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Initiation à la forge avec Stéphane"
                { ...register("title", { required: true }) }
              />
              { errors.title && <div className="form-text text-danger">Un titre est requis</div> }

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="season" className="form-label mt-4">Saison</label>
                  <select 
                    {...register("season", { required: true })}
                    className={`form-control form-control-sm ${ errors.season ? "is-invalid border-danger" : "border-dark" }`}
                  >
                    <option value="">Saison</option>
                    <option value="summer">Été</option>
                    <option value="fall">Automne</option>
                    <option value="winter">Hiver</option>
                    <option value="spring">Printemps</option>
                  </select>
                  { errors.season && <div className="form-text text-danger">Veuillez choisir la saison à laquelle l'atelier a eu lieu</div> }
                </div>
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="year" className="form-label mt-4">Année</label>
                  <select 
                    {...register("year", { required: true })}
                    className={`form-control form-control-sm ${ errors.year ? "is-invalid border-danger" : "border-dark" }`}
                  >
                    <option value="">Année</option>
                    { 
                      yearsSelector().map(year => (
                        <option key={ year } value={ year }>{ year }</option>
                      ))
                    }
                  </select>
                  { errors.year && <div className="form-text text-danger">Veuillez choisir l'année à laquelle l'atelier a eu lieu</div> }
                </div>
              </div>
             
              <label className="form-label mt-4">Illustration <span className="text-muted">(L'image sera recadrée au format paysage)</span></label>
              <input 
                type="file"
                { ...register("file", { required: true }) }
                className={`form-control form-control-sm ${ errors.file ? "is-invalid border-danger" : "border-dark" }`}
              />
              { errors.file && <div className="form-text text-danger">Veuillez choisir un fichier</div> }

              {/* <label htmlFor="description" className="form-label mt-4">Description <span className="text-muted">(280 caractères max)</span></label>
              <textarea 
                type="text"
                id="description"
                rows="4"
                className={ `form-control form-control-sm ${ errors.description ? "is-invalid border-danger" : "border-dark" }` }
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida, ipsum interdum vestibulum sagittis, massa lorem gravida nisl, sit amet sollicitudin odio tortor id sem. Suspendisse et elit mollis, venenatis ex ut, mollis odio. Pellentesque et eros porttitor, bibendum cras."
                maxLength="280"
                { ...register("description", { maxLength: 280 }) }
              />

              <div className="row">
                <div className="col-6">
                  { errors.description && <div className="form-text text-danger">Une description de l'atelier est requise</div> }
                </div>
                <div className="col-6">
                  <p className="text-end mb-0 form-text">{ countText }/280</p> 
                </div>
              </div> */}

              <button className={ `btn mt-5 mb-3 btn-primary w-100` } onClick={ handleSubmit(onSubmit) } type="submit">Créer l'atelier</button>
            </div>
          </div>
        </form>

        {
          success && 
          <Alert message="Atelier ajouté avec succès." setSuccess={ setSuccess } />
        }

      </div>
    </div>
  )
}

export default Create;